import React from 'react'
import { connect } from 'react-redux'
import Layout from '../components/layout'
import Ticker from '../components/Ticker'
import SEO from '../components/seo'
import CookieNotice from '../components/shared/CookieNotice'

const TickerPage = ({ declarePageAsNotLanding }) => {
  declarePageAsNotLanding()

  return (
    <Layout>
      <SEO title='Esports Ticker' />
      <CookieNotice />
      <h1>Ticker | Game-changing esports analytics - Shadow</h1>
      <Ticker />
    </Layout>
  )
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    declarePageAsNotLanding: () => dispatch({ type: `SET_LANDING_PAGE_FALSE` }),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TickerPage)
