import React, { useEffect, useLayoutEffect } from 'react'
import * as styles from './styles.module.scss'
import classnames from 'classnames'

const hTickerSettings = {
  clientId: '384fNwGKoPBzUuQPTRaprXo6sFfKy98t',
}

export const widgetScript = () => `
(function (w, d, s) {
    if (w.SDW) console.error('SDW widget already included');
    w.SDW = {}, w.SDW._r = s; methods = ['mount', 'unmount', 'addWidget', 'updateWidget', 'removeWidget'];
    w.SDW._q = []; methods.forEach(method => w.SDW[method] = function () { w.SDW._q.push([method, arguments]) });
    var script = d.createElement('script'); script.async = 1; script.src = s;
    var before = d.getElementsByTagName('script')[0]; before.parentNode.insertBefore(script, before);
})(window, document, '${'https://widgets.shadow.gg/realtime2/scriptLoader.js'}');
window.SDW.mount(${JSON.stringify(hTickerSettings)});
window.SDW.addWidget({
    containerId: 'shadow-h-ticker',
    settings: { userDidConsent: true, tickerOnly: true},
    type: 'topbar',
});`

const Ticker = () => {
  useEffect(() => {
    if (!window.SDW) {
      const script = document.createElement('script')
      script.setAttribute('id', 'sdw-script')
      script.innerHTML = widgetScript()
      script.async = false
      document.body.appendChild(script)
    } else {
      window.SDW.mount(hTickerSettings)
      window.SDW.addWidget({
        containerId: 'shadow-h-ticker',
        settings: {
          hasPlayerStatsOverlay: true,
          hasDesktopLayout: true,
          userDidConsent: true,
          tickerOnly: true,
          sdwBranding: true,
        },
        type: 'topbar',
      })
    }

    return () => {
      if (window.SDW) {
        window.SDW.removeWidget('shadow-h-ticker')
      }
    }
  }, [])

  useEffect(() => {
    const existingScript = document.getElementById('formScript')
    if (!existingScript) {
      const script = document.createElement('script')
      script.src = 'https://webforms.pipedrive.com/f/loader'
      script.id = 'formScript'
      document.body.appendChild(script)
    }
  }, [])

  return (
    <div className={classnames(styles.ticker, 'col-centered', '--narrow')}>
      <h2 className={styles.h2}>Esports Live Ticker</h2>
      <div id='shadow-h-ticker' className={styles.hTicker} />
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <p>
            Start or upgrade your esports coverage and reach the next generation
            of sports fans with our Esports Live Ticker!
            <br />
            Inform your audience about the latest matches played in esports'
            most relevant titles, give them access to detailed statistics of
            players and teams, and create engaging content for your esports fans
            based on official live in-game data from the world's leading
            supplier of esports data, Bayes Esports.
          </p>
          <p>
            Try out the free version of the Esports Live Ticker now - upgrade to
            the premium version to get access to more detailed stats and
            visualization and our{' '}
            <b>
              <a href='/widget'>media widget</a>
            </b>{' '}
            if and when you need it.
          </p>
          <p>
            If you have questions or need assistance, our service team will help
            you every step of the way. Covering esports has never been this
            easy!
          </p>
          <p>
            <a href='https://www.bayesesports.com/events' target='_blank'>
              <strong>Check out our coverage</strong>
            </a>
          </p>
        </div>
        <div
          class='pipedriveWebForms'
          data-pd-webforms='https://webforms.pipedrive.com/f/6cmpFyes5oYepKEYLkqntomLTN6z4p6lkV7bzSwLRIVmZ0iS9VmRqPlCkzQmFDs8P9'
        />
      </div>
    </div>
  )
}

export default Ticker
